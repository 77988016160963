import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'

/**
 * This page is used to generate a PDF invite for a classroom.
 * It is not accessed directly but from the API with a JWT token for authentication.
 */
export const ClassroomInvitePdfPage = () => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 2000)
  }, [])

  const inviteUrl = `https://coachmate.app/join/INVITE-CODE-TEST`

  return (
    <div className="max-w-md p-10 bg-ui-100">
      <h1 className="text-2xl font-semibold">Your school is doing the challenge</h1>
      <p className="my-4">
        The Big Bash Schools Challenge is a fun-filled 4 week program where students build essential motor skills through in-class activities and
        at-home challenges.
      </p>
      <div>
        <h1>Join your class</h1>
        <div className="mt-4">
          <div className="mb-8">
            <div className="relative min-h-48 text-lg border-2 border-brand-secondary-400 flex flex-col items-center justify-center">
              Invite code
              <span className="font-semibold text-xl">INVITE-CODE-TEST</span>
            </div>
            <div className="bg-brand-secondary-400 p-4 text-center">
              <span className="font-semibold">Go to</span> {inviteUrl.replace(/http(s?):\/\//, '')}
            </div>
          </div>
          <div>
            <div className="flex flex-col items-center border-2 p-4 border-brand-secondary-400">
              <QRCode size={200} value={inviteUrl} viewBox={`0 0 200 200`} />
            </div>
            <div className="bg-brand-secondary-400 p-4 font-semibold text-center">Scan QR Code</div>
          </div>
        </div>
      </div>
      {loaded ? <span className="invisible">Loaded</span> : null}
    </div>
  )
}
